.menu{
    position: fixed;
    bottom: 0;
    min-width: 100%;
    z-index: 10;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
.wrapperMenu{
    align-self: end;
    display: grid;
    grid-template-columns: 30% 40% 30%;
}
.buttonSide{
    display: grid;
    justify-content: center;
    align-content: center;
    border-top-left-radius: 15em;
    border-top-right-radius: 15em;
    padding-top: 0.4em;
    color: white;
    font-size: 3em;
    -webkit-box-shadow: 0px -9px 52px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -9px 52px -8px rgba(0,0,0,0.75);
    box-shadow: 0px -9px 52px -8px rgba(0,0,0,0.75);
    cursor: pointer;
    transition: 0.3s ease-in;
}
.buttonSide:hover{
    font-size: 3.1em;
}
.greenCard{
    display: grid;
    align-content: end;
    cursor: pointer;
}
.greenCard img{
    justify-self: center;
    max-width: 60%;
    transition: 0.5s ease-in-out;
}
.greenCard:hover img{
    transition: 0.5s ease-in-out;
    max-width: 80%;
}
.whatsApp{
    display:grid;
    justify-content: center;
    align-content: center;
    font-size: 1em;
    color: white;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.icon{
    padding: 0.5em;
    border-radius: 1em;
    border: 1px solid white;
}
.whatsApp:hover{
    transition: 0.3s ease-in-out;
    font-size: 1.3em;
}
.NavbarContact{
    background-color: rgb(0, 0, 0,0.5);
    height: 80px;
    display: flex;
    grid-template-columns: repeat(6,1fr);
    position: sticky;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 1;
    margin-bottom: 1em;
    width: 100%;
    /*background-color: rgb(0, 0, 0,0.5);
    height: 80px;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    position: sticky;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 1;
    margin-bottom: 1em;
    width: 100%;*/
}
.NavbarContact a{
    width: 15px;
    height: 15px;
    color: #fff;
    margin: 10px;
}
/*.NavbarContact img{
    width: 80px;
}*/
.logoCentro{
    max-width: 50px;
}

.contactIcon{
    display: grid;
    justify-self: center;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    border-radius: 1em;
    border: 1px solid white;
}
.contactIcon{
    display: grid;
    justify-self: center;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    border-radius: 1em;
    border: 1px solid white;
}

.Gallery{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 0.5em;
    row-gap: 0.5em;
    margin-top: 0.5em;
    min-height: 5em;

}
.imgGallery{
    border-radius: 0.5em;
    min-height: 5em;
    background-position: top;
    background-size: cover
}

.loading{
    background-color: #021e31;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;    
    position: absolute;
}

.logoCarga{
    animation: fill 0.5s ease forwards 1s;
}
.logoCarga path:nth-child(1){
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: line-anim 2s ease forwards;
}
.logoCarga path:nth-child(2){
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    animation: line-anim 2s ease forwards 0.1s;
}
.logoCarga path:nth-child(3){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.2s;
}
.logoCarga path:nth-child(4){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.3s;
}
.logoCarga path:nth-child(5){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.4s;
}
.logoCarga path:nth-child(6){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.5s;
}
.logoCarga path:nth-child(7){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.6s;
}
.logoCarga path:nth-child(8){
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    animation: line-anim 2s ease forwards 0.7s;
}
.logoCarga path:nth-child(9){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.8s;
}
.logoCarga path:nth-child(10){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.8s;
}
.logoCarga path:nth-child(11){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 1s;
}
@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}
@keyframes fill{
    from {
        fill: transparent;
    }
    to{
        fill: #59DDEC;
    }
}
@media (min-width: 768px) {
    .styck{
        width: 25%;
        margin: auto;
        left: 0;
        right: 0;
    }
    .wrapperMenu{
        max-width: 50vh;
        margin:auto;
    }
    .buttonSide{
        font-size: 2.5em;
    }
  }