.Modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ModalCover{
    position: absolute;
    left: 0;
    right: 0;
    min-width: 100vh;
    height: 100vh;
    z-index: -2;
    background-color: white;
    opacity: 0.3;
}
.ModalContent{
    color: white;
    background: rgba(0,0,0,0.9);
    position: relative;
    border-radius: .5rem;
    padding: 1.5rem;
    max-height: 80vh;
    min-width: 80%;
    max-width: 80%;
    font-size: 1.2em;
    overflow:auto;
}
.ModalContent input, textarea{
    background: #263748;
    border: 1px solid #3DE8F0;
    border-radius: 1em;
    padding: 1em;
    color: #fff;
    width: 90%;
    margin-top: 6px;
    margin-bottom: 6px;
}
.ModalContent select{
    background: #263748;
    border: 1px solid #3DE8F0;
    border-radius: 1em;
    padding: 1em;
    color: #fff;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
}
.sectionButtonsModal{
    margin-top: 1em;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-size: 0.5em;
    grid-column-gap: 0.5em;
    justify-content: center;
    align-items: center;
}
.buttonModal{
    text-align: center;
    padding: 0.5em;
    border: 1px solid white;
    border-radius: 1em;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}
.buttonClose{
    position: absolute;
    background: none;
    border: 1px solid white;
    border-radius: 1em;
    color: white;
    text-align: center;
    right: 0;
    margin-right: 2em;
}
.incluye{
    font-size: 0.7em;
}
.hiddentrue{
    display: block;
}
.hiddenfalse{
    display: none;
}
.displayGrid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
@media (min-width: 768px) {
    .ModalContent{
        min-width: 30%;
        max-width: 30%;
    }
    .ModalContent::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .ModalContent::-webkit-scrollbar:vertical {
        width:5px;
    }
    
    .ModalContent::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
        display: none;
    } 
    .ModalContent::-webkit-scrollbar-thumb {
        background-color: #292929;
        border-radius: 20px;
    }
    
    .ModalContent::-webkit-scrollbar-track {
        border-radius: 10px;  
    }

}


.containerAction{
    min-height: 20px;
    width: 110px;
    padding: 1em;
    align-items: center;
    color: #3DE8F0; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: relative;
    border-radius:1em; 
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
}
.containerAction:hover{
    color: #ffff !important;
    background:linear-gradient(45deg,#3de8f0,#00f);
    transition: all 0.3s ease;
}
.containerAction::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:1em; 
    padding:3px; 
    background:linear-gradient(45deg,#3de8f0,#00f); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
}