html {
    scroll-behavior: smooth;
}
@font-face {
    font-family: bwSurco;
    src: url("../fonts/BwSurco.woff") format("woff"),
       url('../fonts/BwSurco.woff')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: americanaBtRoman;
    src: url("../fonts/americana-bt-roman.ttf") format("ttf"),
       url('../fonts/americana-bt-roman.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: aileronsRegular;
    src: url("../fonts/Ailerons-Regular.otf") format("otf"),
       url('../fonts/Ailerons-Regular.otf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: remachinePersonal;
    src: url("../fonts/RemachineScript_Personal_Use.ttf") format("ttf"),
       url('../fonts/RemachineScript_Personal_Use.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
    font-family: jasmithaScript;
    src: url("../fonts/Jasmitha-Script.ttf") format("ttf"),
       url('../fonts/Jasmitha-Script.ttf')  format('truetype'); /* Safari, Android, iOS */
}

.fuentePersonalizada{
    font-family: "americanaBtRoman";
}
  .wrappContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section{
    position:relative;
    width: 400px;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.containerHero{
    z-index: 1;
    margin-top: 20em;
}
@media (min-height: 800px) {
    .containerHero{
        margin-top: 25em;
    }
}
@media (min-height: 850px) {
    .containerHero{
        margin-top: 30em;
    }
}
@media (min-height: 950px) {
    .containerHero{
        margin-top: 35em;
    }
    .vistas{
        margin-top: 2em !important;
    }
}
.slogan{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff;
    /*max-height: 100px;*/
    word-wrap: break-word;
    overflow: hidden;
    font-size: 1.5em;
    margin-bottom: 15px;
}
.logo3{
    display: flex;
    justify-content: center;
}
.logo{
    max-width: 200px;
    display: grid;
    justify-content: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
}
.logo img{
    max-width: 100%;
}

.degradado{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
}

.feed{
    width: 100%;
    height: 100%;
}
.containerFeed{
    display: grid;
    row-gap: 0.5em;
    grid-column-gap: 0.5em;
    grid-template-columns: repeat(2,1fr);
    padding: 0.5em;
}
.containerFeed >div{
    background-color: red;
}
.feed img{
    width: 100%;
    border-radius: 1em;
}


.categorias{
    width: 100%;
    height: 100%;
}
.containerCategorias{
    display: grid;
    row-gap: 0.7em;
    grid-column-gap: 0.7em;
    /*grid-template-columns: repeat(2,1fr);*/
    padding: 0.5em;
}
.containerCategorias >div{
    height: 100%;
    /*height: 250px;*/
}
.serviceTag{
    border-radius: 1em;
    height: 250px;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.degradadoTag{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    border-radius: .2em;
}
.ContectService{
    padding-top: 20px;
    position: inherit;
    color: #fff;
    width: 80%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    bottom: 0;
}
.handleCick{
    position: absolute;
    min-height: 100%;
    width: 100%;
    z-index: 1;
    cursor: pointer;
}
.contentMedia{
    display: grid;
    justify-content: center;
    color: #fff;
    margin-bottom: 5em;
    gap: 1em;
    margin-top: 2em;
}
.socialMedia{
    display: flex;
    justify-content: space-around;
    color: #fff;
    text-decoration: none;
}
.socialMedia a{
    color: #fff;
}
.vistas{
    color: #fff;
    text-align: center;
    margin-top: 1em;
}
.heroImagen{
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
}
.NavbarCategoria::-webkit-scrollbar:horizontal {
    height: 5px;
}
.NavbarCategoria::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 20px;
}
.NavbarCategoria::-webkit-scrollbar {
    -webkit-appearance: none;
}
@media (max-width: 700px) {
    .section{
        width: 100%;
    }
    .hero{
        width: 100% !important;
    }
}


.idiomas{
    color: #fff;
    z-index: 2;
    display: flex;
    list-style: none;
    justify-content: center;
    margin-top: 1em;
}
.idiomas-item{ 
    position: relative;
    margin: 0 1.0em;
    line-height: 2rem;
    cursor: pointer;
}
.idiomas-item::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: white;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 650ms cubic-bezier(0.68,-0.55,0.265,1.55);
}
.idiomas-item:hover::before{
    transform: scaleX(1);
}
@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}
@keyframes fill{
    from {
        fill: transparent;
    }
    to{
        fill: #59DDEC;
    }
}
@media(max-width:768px){
    .menu{
        max-width: 100vw;
    }
  
}
@media (min-width: 768px) {
    
    .styck{
        width: 25%;
        margin: auto;
        left: 0;
        right: 0;
    }
    .wrapperMenu{
        max-width: 50vh;
        margin:auto;
    }
    .buttonSide{
        font-size: 2.5em;
    }
  }

  
.containerActionButton{
    min-height: 20px;
    width: 200px;
    padding: 1em;
    align-items: center;
    color: #fff; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: relative;
    border-radius:1em; 
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
}
.containerActionButton:hover{
    color: #ffff !important;
    background:#5e85ac;
    transition: all 0.3s ease;
}
.containerActionButton::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:1em; 
    padding:3px; 
    background:#5e85ac; 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
}

.sectionButtons{
    margin-top: 1em;
    margin-bottom: 1em;
    display: grid;
    font-size: 1em;
    grid-column-gap: 0.5em;
    justify-content: center;
    align-items: center;
}